import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { Page, Section, Title } from '../components';
import { COL_BRIGHT, COL_DARK } from '../variables';

const Dist = styled.div`
  height: 6rem;
`;

const Content = styled.div`
  a {
    color: ${COL_DARK};
  }
`;
export default function Datenschutz() {
  return (
    <Page>
      <Section color={COL_BRIGHT}>
        <Dist />
        <Title>Datenschutzerklärung</Title>
        <Content>
          <p>
            Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und
            Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz
            „Daten“) im Rahmen der Erbringung unserer Leistungen sowie innerhalb
            unseres Onlineangebotes und der mit ihm verbundenen Webseiten,
            Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B.
            unser Social Media Profile auf (nachfolgend gemeinsam bezeichnet als
            „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten,
            wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf
            die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
          </p>
          <h3 id="dsg-general-controller">Verantwortlicher</h3>
          <p>
            <span class="tsmcontroller">
              Matthias Reis
              <br />
              Große Bergstr. 250
              <br />
              22767 Hamburg
              <br />
              info@amreis.de
              <br />
              <Link to="/impressum">Zum Impressum</Link>
            </span>
          </p>
          <h3 id="dsg-general-datatype">Arten der verarbeiteten Daten</h3>
          <ul>
            <li>
              Bestandsdaten (z.B., Personen-Stammdaten, Namen oder Adressen).{' '}
            </li>
            <li>Kontaktdaten (z.B., E-Mail, Telefonnummern). </li>
            <li>Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos). </li>
            <li>
              Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten).{' '}
            </li>
            <li>
              Meta-/Kommunikationsdaten (z.B., Geräte-Informationen,
              IP-Adressen).
            </li>
          </ul>
          <h3 id="dsg-general-datasubjects">Kategorien betroffener Personen</h3>
          <p>
            Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir
            die betroffenen Personen zusammenfassend auch als „Nutzer“).
          </p>
          <h3 id="dsg-general-purpose">Zweck der Verarbeitung</h3>
          <ul>
            <li>
              Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
              Inhalte.Beantwortung von Kontaktanfragen und Kommunikation mit
              Nutzern.{' '}
            </li>
            <li>Sicherheitsmaßnahmen.</li>
            <li>Reichweitenmessung/Marketing</li>
            <span class="tsmcom" />
          </ul>
          <h3 id="dsg-general-terms">Verwendete Begrifflichkeiten </h3>
          <p>
            „Personenbezogene Daten“ sind alle Informationen, die sich auf eine
            identifizierte oder identifizierbare natürliche Person (im Folgenden
            „betroffene Person“) beziehen; als identifizierbar wird eine
            natürliche Person angesehen, die direkt oder indirekt, insbesondere
            mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
            Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie)
            oder zu einem oder mehreren besonderen Merkmalen identifiziert
            werden kann, die Ausdruck der physischen, physiologischen,
            genetischen, psychischen, wirtschaftlichen, kulturellen oder
            sozialen Identität dieser natürlichen Person sind. „Verarbeitung“
            ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte
            Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
            personenbezogenen Daten. Der Begriff reicht weit und umfasst
            praktisch jeden Umgang mit Daten. „Pseudonymisierung“ die
            Verarbeitung personenbezogener Daten in einer Weise, dass die
            personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen
            nicht mehr einer spezifischen betroffenen Person zugeordnet werden
            können, sofern diese zusätzlichen Informationen gesondert aufbewahrt
            werden und technischen und organisatorischen Maßnahmen unterliegen,
            die gewährleisten, dass die personenbezogenen Daten nicht einer
            identifizierten oder identifizierbaren natürlichen Person zugewiesen
            werden. „Profiling“ jede Art der automatisierten Verarbeitung
            personenbezogener Daten, die darin besteht, dass diese
            personenbezogenen Daten verwendet werden, um bestimmte persönliche
            Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten,
            insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche
            Lage, Gesundheit, persönliche Vorlieben, Interessen,
            Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser
            natürlichen Person zu analysieren oder vorherzusagen. Als
            „Verantwortlicher“ wird die natürliche oder juristische Person,
            Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam
            mit anderen über die Zwecke und Mittel der Verarbeitung von
            personenbezogenen Daten entscheidet, bezeichnet.
            „Auftragsverarbeiter“ eine natürliche oder juristische Person,
            Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten
            im Auftrag des Verantwortlichen verarbeitet.
          </p>
          <h3 id="dsg-general-legalbasis">Maßgebliche Rechtsgrundlagen</h3>
          <p>
            Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
            unserer Datenverarbeitungen mit. Für Nutzer aus dem Geltungsbereich
            der Datenschutzgrundverordnung (DSGVO), d.h. der EU und des EWG
            gilt, sofern die Rechtsgrundlage in der Datenschutzerklärung nicht
            genannt wird, Folgendes: Die Rechtsgrundlage für die Einholung von
            Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO; Die
            Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
            Leistungen und Durchführung vertraglicher Maßnahmen sowie
            Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO; Die
            Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
            rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO; Für den
            Fall, dass lebenswichtige Interessen der betroffenen Person oder
            einer anderen natürlichen Person eine Verarbeitung personenbezogener
            Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
            Rechtsgrundlage. Die Rechtsgrundlage für die erforderliche
            Verarbeitung zur Wahrnehmung einer Aufgabe, die im öffentlichen
            Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die
            dem Verantwortlichen übertragen wurde ist Art. 6 Abs. 1 lit. e
            DSGVO. Die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
            berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Die
            Verarbeitung von Daten zu anderen Zwecken als denen, zu denen sie
            ehoben wurden, bestimmt sich nach den Vorgaben des Art 6 Abs. 4
            DSGVO. Die Verarbeitung von besonderen Kategorien von Daten
            (entsprechend Art. 9 Abs. 1 DSGVO) bestimmt sich nach den Vorgaben
            des Art. 9 Abs. 2 DSGVO.
          </p>
          <h3 id="dsg-general-securitymeasures">Sicherheitsmaßnahmen</h3>
          <p>
            Wir treffen nach Maßgabe der gesetzlichen Vorgabenunter
            Berücksichtigung des Stands der Technik, der Implementierungskosten
            und der Art, des Umfangs, der Umstände und der Zwecke der
            Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeit
            und Schwere des Risikos für die Rechte und Freiheiten natürlicher
            Personen, geeignete technische und organisatorische Maßnahmen, um
            ein dem Risiko angemessenes Schutzniveau zu gewährleisten. Zu den
            Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
            Integrität und Verfügbarkeit von Daten durch Kontrolle des
            physischen Zugangs zu den Daten, als auch des sie betreffenden
            Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verfügbarkeit
            und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet,
            die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und
            Reaktion auf Gefährdung der Daten gewährleisten. Ferner
            berücksichtigen wir den Schutz personenbezogener Daten bereits bei
            der Entwicklung, bzw. Auswahl von Hardware, Software sowie
            Verfahren, entsprechend dem Prinzip des Datenschutzes durch
            Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
          </p>
          <h3 id="dsg-general-coprocessing">
            Zusammenarbeit mit Auftragsverarbeitern, gemeinsam Verantwortlichen
            und Dritten
          </h3>
          <p>
            Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen
            Personen und Unternehmen (Auftragsverarbeitern, gemeinsam
            Verantwortlichen oder Dritten) offenbaren, sie an diese übermitteln
            oder ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur
            auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine
            Übermittlung der Daten an Dritte, wie an Zahlungsdienstleister, zur
            Vertragserfüllung erforderlich ist), Nutzer eingewilligt haben, eine
            rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer
            berechtigten Interessen (z.B. beim Einsatz von Beauftragten,
            Webhostern, etc.). Sofern wir Daten anderen Unternehmen unserer
            Unternehmensgruppe offenbaren, übermitteln oder ihnen sonst den
            Zugriff gewähren, erfolgt dies insbesondere zu administrativen
            Zwecken als berechtigtes Interesse und darüberhinausgehend auf einer
            den gesetzlichen Vorgaben entsprechenden Grundlage.
          </p>
          <h3 id="dsg-general-thirdparty">Übermittlungen in Drittländer</h3>
          <p>
            Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen
            Union (EU), des Europäischen Wirtschaftsraums (EWR) oder der
            Schweizer Eidgenossenschaft) verarbeiten oder dies im Rahmen der
            Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw.
            Übermittlung von Daten an andere Personen oder Unternehmen
            geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer
            (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung,
            aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer
            berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder
            vertraglicher Erlaubnisse, verarbeiten oder lassen wir die Daten in
            einem Drittland nur beim Vorliegen der gesetzlichen Voraussetzungen.
            D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer
            Garantien, wie der offiziell anerkannten Feststellung eines der EU
            entsprechenden Datenschutzniveaus (z.B. für die USA durch das
            „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller
            vertraglicher Verpflichtungen.
          </p>
          <h3 id="dsg-general-rightssubject">
            Rechte der betroffenen Personen
          </h3>
          <p>
            Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
            betreffende Daten verarbeitet werden und auf Auskunft über diese
            Daten sowie auf weitere Informationen und Kopie der Daten
            entsprechend den gesetzlichen Vorgaben. Sie haben entsprechend. den
            gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
            betreffenden Daten oder die Berichtigung der Sie betreffenden
            unrichtigen Daten zu verlangen. Sie haben nach Maßgabe der
            gesetzlichen Vorgaben das Recht zu verlangen, dass betreffende Daten
            unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der
            gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten
            zu verlangen. Sie haben das Recht zu verlangen, dass die Sie
            betreffenden Daten, die Sie uns bereitgestellt haben nach Maßgabe
            der gesetzlichen Vorgaben zu erhalten und deren Übermittlung an
            andere Verantwortliche zu fordern. Sie haben ferner nach Maßgabe der
            gesetzlichen Vorgaben das Recht, eine Beschwerde bei der zuständigen
            Aufsichtsbehörde einzureichen.
          </p>
          <h3 id="dsg-general-revokeconsent">Widerrufsrecht</h3>
          <p>
            Sie haben das Recht, erteilte Einwilligungen mit Wirkung für die
            Zukunft zu widerrufen.
          </p>
          <h3 id="dsg-general-object">Widerspruchsrecht</h3>
          <p>
            <strong>
              Sie können der künftigen Verarbeitung der Sie betreffenden Daten
              nach Maßgabe der gesetzlichen Vorgaben jederzeit widersprechen.
              Der Widerspruch kann insbesondere gegen die Verarbeitung für
              Zwecke der Direktwerbung erfolgen.
            </strong>
          </p>
          <h3 id="dsg-general-cookies">
            Cookies und Widerspruchsrecht bei Direktwerbung
          </h3>
          <p>
            Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der
            Nutzer gespeichert werden. Innerhalb der Cookies können
            unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär
            dazu, die Angaben zu einem Nutzer (bzw. dem Gerät auf dem das Cookie
            gespeichert ist) während oder auch nach seinem Besuch innerhalb
            eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw.
            „Session-Cookies“ oder „transiente Cookies“, werden Cookies
            bezeichnet, die gelöscht werden, nachdem ein Nutzer ein
            Onlineangebot verlässt und seinen Browser schließt. In einem solchen
            Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop
            oder ein Login-Status gespeichert werden. Als „permanent“ oder
            „persistent“ werden Cookies bezeichnet, die auch nach dem Schließen
            des Browsers gespeichert bleiben. So kann z.B. der Login-Status
            gespeichert werden, wenn die Nutzer diese nach mehreren Tagen
            aufsuchen. Ebenso können in einem solchen Cookie die Interessen der
            Nutzer gespeichert werden, die für Reichweitenmessung oder
            Marketingzwecke verwendet werden. Als „Third-Party-Cookie“ werden
            Cookies bezeichnet, die von anderen Anbietern als dem
            Verantwortlichen, der das Onlineangebot betreibt, angeboten werden
            (andernfalls, wenn es nur dessen Cookies sind spricht man von
            „First-Party Cookies“). Wir können temporäre und permanente Cookies
            einsetzen und klären hierüber im Rahmen unserer Datenschutzerklärung
            auf. Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner
            gespeichert werden, werden sie gebeten die entsprechende Option in
            den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte
            Cookies können in den Systemeinstellungen des Browsers gelöscht
            werden. Der Ausschluss von Cookies kann zu Funktionseinschränkungen
            dieses Onlineangebotes führen. Ein genereller Widerspruch gegen den
            Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann
            bei einer Vielzahl der Dienste, vor allem im Fall des Trackings,
            über die US-amerikanische Seite{' '}
            <a href="http://www.aboutads.info/choices/">
              http://www.aboutads.info/choices/
            </a>{' '}
            oder die EU-Seite{' '}
            <a href="http://www.youronlinechoices.com/">
              http://www.youronlinechoices.com/
            </a>{' '}
            erklärt werden. Des Weiteren kann die Speicherung von Cookies
            mittels deren Abschaltung in den Einstellungen des Browsers erreicht
            werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle
            Funktionen dieses Onlineangebotes genutzt werden können.
          </p>
          <h3 id="dsg-general-erasure">Löschung von Daten</h3>
          <p>
            Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
            Vorgaben gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern
            nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben,
            werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre
            Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine
            gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten
            nicht gelöscht werden, weil sie für andere und gesetzlich zulässige
            Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt.
            D.h. die Daten werden gesperrt und nicht für andere Zwecke
            verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
            steuerrechtlichen Gründen aufbewahrt werden müssen.
          </p>
          <h3 id="dsg-general-changes">
            Änderungen und Aktualisierungen der Datenschutzerklärung
          </h3>
          <p>
            Wir bitten Sie sich regelmäßig über den Inhalt unserer
            Datenschutzerklärung zu informieren. Wir passen die
            Datenschutzerklärung an, sobald die Änderungen der von uns
            durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
            informieren Sie, sobald durch die Änderungen eine
            Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine
            sonstige individuelle Benachrichtigung erforderlich wird.
          </p>
          <p />
          <h3 id="dsg-tracking-tagmanager">Google Tag Manager</h3>
          <p />
          <p>
            <span class="ts-muster-content">
              Google Tag Manager ist eine Lösung, mit der wir sog. Website-Tags
              über eine Oberfläche verwalten können (und so z.B. Google
              Analytics sowie andere Google-Marketing-Dienste in unser
              Onlineangebot einbinden). Der Tag Manager selbst (welches die Tags
              implementiert) verarbeitet keine personenbezogenen Daten der
              Nutzer. Im Hinblick auf die Verarbeitung der personenbezogenen
              Daten der Nutzer wird auf die folgenden Angaben zu den
              Google-Diensten verwiesen. Nutzungsrichtlinien:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/intl/de/tagmanager/use-policy.html">
                https://www.google.com/intl/de/tagmanager/use-policy.html
              </a>
              .
            </span>
          </p>
          <p />
          <h3 id="dsg-ga-googleanalytics">Google Analytics</h3>
          <p />
          <p>
            <span class="ts-muster-content">
              Wir setzen auf Grundlage unserer berechtigten Interessen (d.h.
              Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb
              unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO)
              Google Analytics, einen Webanalysedienst der Google LLC („Google“)
              ein. Google verwendet Cookies. Die durch das Cookie erzeugten
              Informationen über Benutzung des Onlineangebotes durch die Nutzer
              werden in der Regel an einen Server von Google in den USA
              übertragen und dort gespeichert. Google ist unter dem
              Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine
              Garantie, das europäische Datenschutzrecht einzuhalten (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
              </a>
              ). Google wird diese Informationen in unserem Auftrag benutzen, um
              die Nutzung unseres Onlineangebotes durch die Nutzer auszuwerten,
              um Reports über die Aktivitäten innerhalb dieses Onlineangebotes
              zusammenzustellen und um weitere, mit der Nutzung dieses
              Onlineangebotes und der Internetnutzung verbundene
              Dienstleistungen, uns gegenüber zu erbringen. Dabei können aus den
              verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt
              werden. Wir setzen Google Analytics nur mit aktivierter
              IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer
              wird von Google innerhalb von Mitgliedstaaten der Europäischen
              Union oder in anderen Vertragsstaaten des Abkommens über den
              Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird
              die volle IP-Adresse an einen Server von Google in den USA
              übertragen und dort gekürzt. Die von dem Browser des Nutzers
              übermittelte IP-Adresse wird nicht mit anderen Daten von Google
              zusammengeführt. Die Nutzer können die Speicherung der Cookies
              durch eine entsprechende Einstellung ihrer Browser-Software
              verhindern; die Nutzer können darüber hinaus die Erfassung der
              durch das Cookie erzeugten und auf ihre Nutzung des
              Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung
              dieser Daten durch Google verhindern, indem sie das unter
              folgendem Link verfügbare Browser-Plugin herunterladen und
              installieren:&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://tools.google.com/dlpage/gaoptout?hl=de">
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              . Weitere Informationen zur Datennutzung durch Google,
              Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der
              Datenschutzerklärung von Google (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/technologies/ads">
                https://policies.google.com/technologies/ads
              </a>
              ) sowie in den Einstellungen für die Darstellung von
              Werbeeinblendungen durch Google{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://adssettings.google.com/authenticated">
                (https://adssettings.google.com/authenticated
              </a>
              ). Die personenbezogenen Daten der Nutzer werden nach 14 Monaten
              gelöscht oder anonymisiert.
            </span>
          </p>
          <p />
          <h3 id="dsg-socialmedia">Onlinepräsenzen in sozialen Medien</h3>
          <p />
          <p>
            <span class="ts-muster-content">
              Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
              Plattformen, um mit den dort aktiven Kunden, Interessenten und
              Nutzern kommunizieren und sie dort über unsere Leistungen
              informieren zu können. Wir weisen darauf hin, dass dabei Daten der
              Nutzer außerhalb des Raumes der Europäischen Union verarbeitet
              werden können. Hierdurch können sich für die Nutzer Risiken
              ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer
              erschwert werden könnte. Im Hinblick auf US-Anbieter die unter dem
              Privacy-Shield zertifiziert sind, weisen wir darauf hin, dass sie
              sich damit verpflichten, die Datenschutzstandards der EU
              einzuhalten. Ferner werden die Daten der Nutzer im Regelfall für
              Marktforschungs- und Werbezwecke verarbeitet. So können z.B. aus
              dem Nutzungsverhalten und sich daraus ergebenden Interessen der
              Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile können
              wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und
              außerhalb der Plattformen zu schalten, die mutmaßlich den
              Interessen der Nutzer entsprechen. Zu diesen Zwecken werden im
              Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in
              denen das Nutzungsverhalten und die Interessen der Nutzer
              gespeichert werden. Ferner können in den Nutzungsprofilen auch
              Daten unabhängig der von den Nutzern verwendeten Geräte
              gespeichert werden (insbesondere wenn die Nutzer Mitglieder der
              jeweiligen Plattformen sind und bei diesen eingeloggt sind). Die
              Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf
              Grundlage unserer berechtigten Interessen an einer effektiven
              Information der Nutzer und Kommunikation mit den Nutzern gem. Art.
              6 Abs. 1 lit. f. DSGVO. Falls die Nutzer von den jeweiligen
              Anbietern der Plattformen um eine Einwilligung in die
              vorbeschriebene Datenverarbeitung gebeten werden, ist die
              Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. a., Art. 7
              DSGVO. Für eine detaillierte Darstellung der jeweiligen
              Verarbeitungen und der Widerspruchsmöglichkeiten (Opt-Out),
              verweisen wir auf die nachfolgend verlinkten Angaben der Anbieter.
              Auch im Fall von Auskunftsanfragen und der Geltendmachung von
              Nutzerrechten, weisen wir darauf hin, dass diese am effektivsten
              bei den Anbietern geltend gemacht werden können. Nur die Anbieter
              haben jeweils Zugriff auf die Daten der Nutzer und können direkt
              entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie
              dennoch Hilfe benötigen, dann können Sie sich an uns wenden. -
              Facebook, -Seiten, -Gruppen, (Facebook Ireland Ltd., 4 Grand Canal
              Square, Grand Canal Harbour, Dublin 2, Irland) auf Grundlage einer{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/legal/terms/page_controller_addendum">
                Vereinbarung über gemeinsame Verarbeitung personenbezogener
                Daten
              </a>{' '}
              - Datenschutzerklärung:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/about/privacy/">
                https://www.facebook.com/about/privacy/
              </a>
              , speziell für Seiten:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/legal/terms/information_about_page_insights_data">
                https://www.facebook.com/legal/terms/information_about_page_insights_data
              </a>{' '}
              , Opt-Out:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/settings?tab=ads">
                https://www.facebook.com/settings?tab=ads
              </a>{' '}
              und{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.youronlinechoices.com">
                http://www.youronlinechoices.com
              </a>
              , Privacy Shield:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active
              </a>
              . - Google/ YouTube (Google LLC, 1600 Amphitheatre Parkway,
              Mountain View, CA 94043, USA) – Datenschutzerklärung: &nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/privacy">
                https://policies.google.com/privacy
              </a>
              , Opt-Out:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://adssettings.google.com/authenticated">
                https://adssettings.google.com/authenticated
              </a>
              , Privacy Shield:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
              </a>
              . - Twitter (Twitter Inc., 1355 Market Street, Suite 900, San
              Francisco, CA 94103, USA) - Datenschutzerklärung:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/de/privacy">
                https://twitter.com/de/privacy
              </a>
              , Opt-Out:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/personalization">
                https://twitter.com/personalization
              </a>
              , Privacy Shield:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active
              </a>
              .
            </span>
          </p>
          <p />
          <h3 id="dsg-thirdparty-googlefonts">Google Fonts</h3>
          <p />
          <p>
            <span class="ts-muster-content">
              Wir binden die Schriftarten ("Google Fonts") des Anbieters Google
              LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein.
              Datenschutzerklärung:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/policies/privacy/">
                https://www.google.com/policies/privacy/
              </a>
              , Opt-Out:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://adssettings.google.com/authenticated">
                https://adssettings.google.com/authenticated
              </a>
              .
            </span>
          </p>
          <a
            href="https://datenschutz-generator.de"
            class="dsg1-6"
            target="_blank"
            rel="nofollow noopener noreferrer">
            Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke
          </a>
        </Content>
      </Section>
    </Page>
  );
}
